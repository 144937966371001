<template>
  <div>
    <div class="setting-nav-title">
      <!-- {{ $t("tradeTypeManage") }} -->
      <!-- <el-button class="confirm pull-right" @click="applyNewPayment">{{ $t("newpayment") }}</el-button> -->
    </div>
    <el-row style="height:60px;">
      <el-col :span="12"><el-button @click="createTenantDialog = true">CreateTenantConfig</el-button></el-col>
      <el-col :span="12" style="text-align: right;"
        ><el-select v-model="tenantFilter">
          <el-option value="">all</el-option>
          <el-option
            v-for="item in tenantList"
            :key="item.tenantId"
            :value="item.id"
            :label="item.tenantName"
          ></el-option> </el-select
      ></el-col>
    </el-row>
    <div class="payment-container" v-loading="loading">
      <nodata v-if="configList.length === 0" />
      <el-table v-else :data="filterConfigList()">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column prop="configName" label="configName"></el-table-column>
        <el-table-column prop="configTypeId" label="configTypeId"></el-table-column>
        <el-table-column prop="tenantId" label="tenantId"></el-table-column>
        <!-- <el-table-column prop="configValue" label="configValue"></el-table-column> -->
        <el-table-column fixed="right" label="" width="200">
          <template slot-scope="scope">
            <el-button @click="eTenant(scope.row)" type="text" size="small">EDIT</el-button>
            <el-button @click="dTenant(scope.row)" type="text" size="small">DELETE</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="" :visible.sync="createTenantDialog" v-loading="loading" @close="handelDialogClose">
      <el-form :model="form" ref="form">
        <el-form-item label="configName" prop="configName" required>
          <el-input v-model="form.configName"></el-input>
        </el-form-item>
        <el-form-item label="configTypeId" prop="configTypeId" required>
          <el-select v-model="form.configTypeId">
            <el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="tenantId" prop="tenantId" required>
          <el-select v-model="form.tenantId">
            <el-option v-for="item in tenantList" :key="item.id" :label="item.tenantName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="remark" prop="remark" required>
          <el-input v-model="form.remark"></el-input>
        </el-form-item> -->

        <el-form-item label="configValue" prop="configValue" required> </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="form.configValue" type="textarea" :rows="10"></el-input>
          </el-col>

          <el-col :span="12">
            <pre style="white-space: pre-wrap;" v-html="syntaxHighlight(form.configValue)"></pre>
          </el-col>
        </el-row>

        <el-button @click="cTenant()">Submit</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import paymentusing from "@/assets/using.png";
import paymentchecking from "@/assets/checking.png";
import paymentappling from "@/assets/appling.png";
import paymentfailed from "@/assets/failed.png";
import {
  getTenants,
  getTenantConfigType,
  createTenant,
  delTenant,
  updateTenant,
  getPaymentClasses,
  getPaymentTypes
} from "api/tenant";

import { getTenantConfigs, createTenantConfig, deleteTenantConfig, updateTenantConfig } from "api/tenant";
import { getBigPayLogoByTradeTypeName } from "utils/tradeTypeLogo";
import { rawSplit } from "@chenfengyuan/vue-qrcode";
export default {
  data() {
    return {
      loading: false,
      createTenantDialog: false,
      paymentusing,
      paymentchecking,
      paymentappling,
      paymentfailed,
      paylist: [],
      tenantList: [],
      typeList: [],
      configList: [],
      paymentClassList: [],
      paymentTypeList: [],
      form: {
        id: 0,
        tenantId: 1,
        configName: "",
        configTypeId: 1,
        configValue: ""
      },
      tenantFilter: ""
    };
  },
  mounted() {
    this.gTenant();
    // let pc = getPaymentClasses().then(res => {
    //   console.log(res);
    //   if (res.statusCode === 200 && res.resultStatus === "Success") {
    //     this.paymentClassList = res.result;
    //   }
    // });
    // let pt = getPaymentTypes().then(res => {
    //   console.log(res);
    //   if (res.statusCode === 200 && res.resultStatus === "Success") {
    //     this.paymentTypeList = res.result;
    //   }
    // });
  },
  methods: {
    filterConfigList() {
      if (this.tenantFilter) {
        return this.configList.filter(item => item.tenantId === this.tenantFilter);
      } else {
        return this.configList;
      }
    },
    syntaxHighlight(json) {
      if (typeof json != "string") {
        json = JSON.stringify(json, undefined, 2);
      }
      json = json
        .replace(/&/g, "&")
        .replace(/</g, "<")
        .replace(/>/g, ">");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function(match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },
    handelDialogClose() {
      this.form = {
        id: 0,
        tenantId: 1,
        configName: "",
        configTypeId: 1,
        configValue: ""
      };
      this.$refs.form.resetFields();
    },
    gTenant() {
      getTenants({ pageSize: 9999 }).then(res => {
        console.log(res);
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.tenantList = res.result;
        }
      });
      getTenantConfigType({ pageSize: 9999 }).then(res => {
        console.log(res);
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.typeList = res.result;
        }
      });

      return getTenantConfigs({ pageSize: 9999 }).then(res => {
        console.log(res);
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.configList = res.result;
        }
      });
    },
    cTenant() {
      this.$refs.form.validate(valid => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          let xhr;

          if (this.form.id > 0) {
            xhr = updateTenantConfig(this.form);
          } else {
            xhr = createTenantConfig(this.form);
          }

          xhr
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                // this.tenantList = res.result;
                this.createTenantDialog = false;
                this.gTenant();
              }
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    dTenant(row) {
      this.$confirm("Confirm?", "warning", {
        type: "warning"
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.loading = true;

          deleteTenantConfig({ id: row.id, tenantId: row.tenantId })
            .then(res => {
              console.log(res);
              this.loading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.gTenant().then(res => {
                  console.log(res);
                  this.loading = false;
                });
              }
            })
            .catch(err => {
              this.loading = false;
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    eTenant(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.createTenantDialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
::v-deep pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}
::v-deep .string {
  color: green;
}
::v-deep .number {
  color: darkorange;
}
::v-deep .boolean {
  color: blue;
}
::v-deep .null {
  color: magenta;
}
::v-deep .key {
  color: red;
}

.notification-container {
  margin: 16px 0;
}

.payment-container {
  background: #fffffe;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 0 24px;
}

.payment-item {
  @include flex();

  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border: none;
  }

  .payment-img-container {
    width: 122px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    border-radius: 8px;

    & > img {
      width: 90px;
      height: 54px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .payment-content {
    flex: 1;
    padding: 0 30px;

    .payment-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
    }

    .payment-rate {
      font-size: 12px;
    }
  }

  .payment-status {
    line-height: 24px;

    .payment-status-image {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .status-text {
      font-size: 14px;
      color: #2aa76c;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>
